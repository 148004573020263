.table-options {

  .ant-select {
    font-size: 15px;
    line-height: 19px;
    width: 170px;
    //color: var(--text-color-main);
    color: var(--text-color-main-calm);
  }

  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: 5px;
    border: 1px solid var(--border-input-color);
    padding: 10px 8px;
    height: 40px;
  }

  .ant-select-single .ant-select-selector .ant-select-selection-item, .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
    line-height: unset;
  }

  .ant-select-focused {
    .ant-select-selector {
      box-shadow: none !important;
      border-color: var(--border-input-color) !important;
    }
  }

  // .ant-select-arrow {
  //   // top: 55%;
  // }

  &-select {

    &--bold {

      .ant-select-selection-item {
        font-weight: bold;
        color: var(--text-color-main);
      }
    }
  }
}

.ant-select-item-option-active.ant-select-item-option-selected {
  background: var(--bg-color-btn) !important;
  color: var(--bg-color-main) !important;
}

.select {

  &--transparent {
    .ant-select-selector {
      background: var(--border-input-color) !important;
    }
  }
}
