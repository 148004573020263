.login-page {

  &__wrapper {
    width: 100vw;
    height: 100vh;
    display: flex;
  }
  &__image {
    width: 62%;
    background: url("../../assets/images/login-image.jpeg") no-repeat center center;
    background-size: cover;
  }

  &--logo {
    width: 62px;
    height: 62px;
    background: url("../../assets/images/login-logo.png") no-repeat center center;
    background-size: cover;
    margin-bottom: 46px;
  }

  &--title {
    color: var(--text-color-main);
    font-size: 56px;
    line-height: 32px;
    margin-bottom: 46px;
  }

  &__form {
    background: var(--bg-color-main);
    width: 32%;
    max-width: 288px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;

    &__wrapper {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  &__forgot-password {
    cursor: pointer;
    text-decoration: underline;
    color: var(--text-color-main);
    font-size: 16px;
    line-height: 25px;
    margin-top: 18px;
  }
}

.ant-form.ant-form-horizontal {
  width: 100%;
  text-align: center;
}
