.header-navigation {
  width: auto;

  &-list {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0;

    li {
      margin: 0 16px;
    }
    .header-link-divider {
      margin: 0;
    }
  }

  .ant-btn:hover, .ant-btn:focus {
    color: var(--link-color-disabled);
    border-color: unset;
    background: unset;
  }
}

.header-link {
  text-transform: capitalize;
  color: var(--link-color-disabled);
  text-decoration: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: unset;
  padding: unset;
  background: unset;

  &--active {
    color: var(--bg-color-btn);
  }

  &-icon {
    width: 24px;
    height: 24px;
    margin-bottom: 5px;
  }

  &-divider {
    background: var(--link-color-disabled);
    width: 1px;
    height: 20px;
  }

  &.active span {
    color: var(--bg-color-btn);
  }
}

.bell {
  display: flex;
  position: relative;
}

.ellips {
  position: absolute;
  top: -6px;
  left: 12px;
}
