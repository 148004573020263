.refundable-info {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 16px;
  > div {
    font-size: 16px;
    font-weight: 500;
    color: var(--text-color-main);
  }
}

#refundable-form {
  &.ant-form {
    .ant-input-number-group-wrapper {
      width: 100%;
    }
    .ant-input-number {
      border-radius: 2px;
    }
  }
}
