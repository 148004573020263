.permissions-table-wrapper {
  width: 80%;
  margin: 0 auto;
  border-radius: 4px;
  padding: 8px;
  background-color: #ffff;
}

th.ant-table-cell.permissions {
  padding: 12px 8px;
  &.functionality {
    padding-left: 40px !important;
  }
}

td.ant-table-cell.permissions {
  padding: 3px 8px !important;
  &.functionality {
    padding-left: 40px !important;
  }
}

.ant-checkbox-inner {
	width: 20px!important;
	height: 20px!important;
	background-color: #ffff;
	border-radius: 4px;
	border: 1px solid var(--bg-color-btn);
}

.ant-checkbox-checked .ant-checkbox-inner {
	background: var(--bg-color-btn);
	border: 1px solid var(--bg-color-btn);
	border-radius: 5px;
}

.ant-checkbox-disabled .ant-checkbox-inner {
  // background: rgb(237, 235, 235);
  background: var(--bg-color-disabled );
}
