.table-options {

  &-customize {
    font-weight: bold;
    height: 40px;
    font-size: 15px;
    line-height: 18px;
    color: var(--text-color-main);
    border: 1px solid var(--text-color-main);
    background: transparent;
    padding: 15px 9px;
    display: flex;
    align-items: center;

    &-icon {
      display: flex;
      margin-right: 5px;
    }
  }

  &-page-size {
    width: 45px !important;
    margin-right: 8px;

    .ant-select-selector {
      width: fit-content !important;
    }

    .ant-select-selector .ant-select-selection-item {
      padding-right: 10px;
    }

    .ant-select-arrow {
      right: 3px;
      opacity: 100% !important;
      // top: 50% !important;
    }
  }

  &-actions {
    display: flex;
    align-items: center;
    height: 49px;

    &-button {
      width: 40px;
      height: 40px;
      background: var(--bg-color-btn);
      border-radius: 5px;
      display: flex;
      justify-content: center;
      align-items: center;

      &:hover {
        background: var(--bg-color-btn);
      }
    }

    & .ant-select-selection-item {
      font-weight: bold;
      color: var(--text-color-main);
    }
  }
}
