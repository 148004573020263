
.notifications-content {
  background: var(--bg-color-input);
  padding: 24px;
  width: 90%;
  margin: auto;
  .title {
    font-size: 20px;
    line-height: 32px;
    font-weight: 600;
    // color: var(--text-color-main);
    color: var(--bg-color-btn);
    margin-left: 40px;
  }
  .not-found {
    text-align: center;
    width: 100%;
    color: var(--text-color-main-calm);
    font-weight: 500;
  }
  .note {
    background: var(--bg-color-main);
    border-radius: 16px;
    padding: 10px 14px;
    margin-bottom: 14px;
    &.grey {
      color: #78797d;
    }
    &_row {
      display: flex;
      justify-content: space-between;
      &__text {
        border: 1px solid var(--bg-color-disabled);
        // border: 1px solid var(--border-input-color);
        width: 72%;
        border-radius: 8px;
        padding: 8px;
        &.new {
          font-weight: 500;
          border-color: #526dcb;
        }
      }
      &__date {
        color: var(--text-color-main-calm);
        display: flex;
        align-items: center;
        padding: 0 10px;
        justify-content: center;
        min-width: 24%;
        > span {
          width: 5px;
        }
      }
      // &__delete {
      //   display: flex;
      //   align-items: center;
      //   justify-content: space-between;
      // }
      &__item-id {
        width: 19%;
        display: flex;
        align-items: center;
        margin-right: 5px;
        color: #526dcb;
        &:hover {
          cursor: pointer;
        }
      } 
    }
    // &_author {
      //   color: #f5605d;
      //   font-weight: 500;
      // }
    }
  }
