.ant-pagination {
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  padding: 10px 25px;
  background: var(--bg-color-input);
  border-radius: 10px;
  &.history-popup {
    position: relative;
    padding: 5px 10px;
    border-radius: 4px;
    background: var(--bg-color-main);
    margin: 0 auto;
    width: fit-content;
    transform: none;
    left: 0;
    &:after {
      visibility: hidden;
    }
    &:before {
      visibility: hidden;
    }
  }

  &-prev .ant-pagination-item-link, &-next .ant-pagination-item-link{
    background: transparent;
    border: none;
  }

  &-item {
    font-size: 16px;
    line-height: 24px;
    font-family: Jost, sans-serif;
    border: none;
    background: transparent;
    height: unset;

    &-active {
      background: transparent;
      border: none;

      & a {
        color: #7796FF !important;
      }
    }

    & a {
      padding: 0;
      color: var(--text-color-main);
    }
  }

  &:before {
    position: absolute;
    content: '';
    left: -13px;
    bottom: 0;
    border-bottom: 15px solid var(--bg-color-input);
    border-left: 20px solid transparent;
    width: 0;
    height: 0;
  }

  &:after {
    visibility: visible;
    position: absolute;
    content: '';
    right: -13px;
    bottom: 0;
    border-bottom: 15px solid var(--bg-color-input);
    border-right: 20px solid transparent;
    width: 0;
    height: 0;
  }
}
