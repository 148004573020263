.header {
  width: 100%;
  height: 72px;
  background: var(--bg-color-input);
  padding: 20px 20px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &-content {
    display: flex;
    align-items: center;
  }

  &-logo {
    width: 32px;
    height: 32px;
    background: url("../../assets/images/login-logo.png") no-repeat center center;
    background-size: cover;
  }

  &-title {
    margin-left: 40px;
    font-weight: bold;
    font-size: 32px;
    line-height: 32px;
    color: var(--text-color-main);
    text-transform: capitalize;
    margin-bottom: 0;
  }
}
