.table-options {

  .ant-picker {
    padding: 10px 8px;
    margin-right: 12px;
    font-size: 15px;
    line-height: 19px;
    width: 227px;
    color: var(--text-color-main);
    border: 1px solid var(--border-input-color);
    border-radius: 5px;
    height: 40px;
  }
  .ant-picker-focused {
    box-shadow: none;
  }
}
