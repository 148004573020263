html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}
body {
  line-height: 1;
}
ol, ul {
  list-style: none;
}
blockquote, q {
  quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

// @font-face {
//   font-family: Noah;
//   src: url("./assets/fonts/noah-regular.otf") format("opentype");
//   font-weight: normal;
// }

// @font-face {
//   font-family: Noah;
//   src: url("./assets/fonts/noah-bold.otf") format("opentype");
//   font-weight: bold;
// }

@font-face {
  font-family: Jost;
  src: url("./assets/fonts/Jost-Light.ttf") format("truetype");
  font-weight: 300;
}

@font-face {
  font-family: Jost;
  src: url("./assets/fonts/Jost-Regular.ttf") format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: Jost;
  src: url("./assets/fonts/Jost-Medium.ttf") format("truetype");
  font-weight: 500;
}

@font-face {
  font-family: Jost;
  src: url("./assets/fonts/Jost-SemiBold.ttf") format("truetype");
  font-weight: 600;
}

@font-face {
  font-family: Jost;
  src: url("./assets/fonts/Jost-Bold.ttf") format("truetype");
  font-weight: 700;
}

body {
  font-family: Jost;
  overflow-x: hidden;
}

div, p, span, a {
  font-family: Jost;
}

:root {
  --text-color-main: #232D53;
  --text-color-main-calm: #a0a5b5;
  --link-color-disabled: #a8acbb;
  --bg-color-main: #ffffff;
  --bg-color-input: #f0eef6;
  --bg-color-btn: #526dcb;
  --bg-color-btn-active: #1940ca;
  --bg-color-btn-hover: #262f56;
  --bg-icon-calm: #a6aaba;
  --bg-color-disabled: #f5f5f5;
  --border-input-color: #e5e1ef;
  --text-red-color: #aa4a41;
}

.ant-layout {
  background: #ffffff !important;
}
