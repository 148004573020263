.add-note-button-cancel {
  margin-left: auto;
  margin-right: 15px;
  margin-top: 10px;
  &:hover {
    cursor: pointer;
    color: var(--text-red-color);
  }
}

.add-note-button-add {
  margin-top: 10px;
  color: var(--bg-color-btn);
  &:hover {
    cursor: pointer;
    color: var(--bg-color-btn-active);
  }
}

textarea.ant-input.add-note-popup {
  padding: 6px;
  border-radius: 8px;
  border-color: var(--border-input-color);
}

.notes-filtres {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 33.3%;
  height: 40px;
  border: 1px solid var(--border-input-color);
  background: var(--bg-color-main);
  font-weight: 500;
  &:first-child {
    border-radius: 4px 0 0 4px;
  }
  &:last-child {
    border-radius: 0 4px 4px 0;
  }
  &.active {
    // background: var(--bg-color-btn-hover);
  }
  &:hover {
    cursor: pointer;
    background: var(--bg-color-input);
  }
}

.note {
 margin: 10px 0 10px 0;
}

.note-history-date {
  font-size: 12px;
  color: var(--text-color-main-calm)
}

.note-history-col {
  background: var(--bg-color-input);
  border-radius: 4px;
  border: 1px solid var(--border-input-color);
  padding: 6px 4px;
}

.comment-name {
  margin-left: 10px;
}
.not-found {
  text-align: center;
  margin: 30px;
}
