.product-card-page {
  background: var(--bg-color-input);
  border-top: 1px solid #D7DCE5;
  padding: 24px 12px;
  display: flex;
  flex-wrap: nowrap;

  .block {
    display: flex;
    flex-direction: column;
    padding: 0 12px;

    &.product-info {
      flex: 1;
      max-width: 320px;
    }

    &.source {
      // flex: 5;
      display: flex;
      flex-direction: column;
      .content {
        // flex: 1;
        min-height: 180px;
        &.suppliers {
          min-height: 180px;
        }
        .title {
          display: flex;
          justify-content: space-between;
          margin-bottom: 16px;
          h3 {
            font: normal normal 500 24px/32px Jost;
            color: var(--text-color-main);
            margin-bottom: 0;
            margin-left: 24px;
          }
        }
      }
    }

    .ava {
      width: 100%;
      height: 200px;
      border-radius: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 24px;
      color: var(--text-color-main);
      background: var(--bg-color-main);
      margin-top: 56px;
      margin-bottom: 12px;
      padding: 16px;
      > div {
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain
      }
    }

    .card-item {
      min-height: 40px;
      width: 100%;
      background: var(--bg-color-main);
      color: var(--text-color-main);
      margin-bottom: 12px;
      padding: 8px;
      display: flex;
      align-items: center;
      border-radius: 5px;
      > a {
        text-overflow: ellipsis;
        overflow: hidden;
        text-decoration: underline;
      }
    }

    .card-label {
      margin-left: 8px;
      margin-bottom: 4px;
      color: var(--text-color-main);
      font-size: 12px;
      font-weight: 600;
    }
  }

  .footer {
    width: 100%;
    height: fit-content;
    margin-top: auto;
    display: flex;
    justify-content: end;

    button {
      width: 240px;
      margin: 0 8px;
    }
  }
}

.comment {
  margin-bottom: 4px;
  width: fit-content;
  color: var(--text-color-main-calm);

  &-date {
    font-size: 12px;
    margin-right: 10px;
  }
 
  &-name {
    font-size: 12px;
  }

  &-text {
    color: var(--text-color-main);
    background: var(--bg-color-disabled);
    border-radius: 8px;
    padding: 2px 8px;
  }
}

.comments-title {
  font: normal normal 500 24px/32px Jost;
  color: var(--text-color-main);
  margin-bottom: 10px;
  margin-left: 24px;
}

.comments-wrapper.products {
  border: 1px solid var(--bg-color-main);
  padding: 16px;
  border-radius: 5px;
  background: var(--bg-color-main);
  margin-bottom: 20px;
}
