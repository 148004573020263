.content-page {
  height: 100%;
  .table-options {
    padding: 4px 20px 5px;
    background: var(--bg-color-input);
    display: flex;
    align-items: center;
    justify-content: space-between;

    &-inputs {
      display: flex;
      align-items: center;
    }

    &-search {
      padding-right: 40px;
    }

    &-button {
      background: var(--bg-color-btn);
      color: var(--bg-color-main);
      font-weight: bold;
      font-size: 15px;
      line-height: 18px;
      padding: 12px;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 40px;
      &:hover {
        background: var(--bg-color-btn-hover);
      }
    }

    &-upload {
      margin-left: 20px;
    }

    .ant-select {
      margin-right: 12px;

      &:first-child {
        margin-left: 0;
      }
    }

    .header-link-divider {
      margin: 0 16px;
    }
    
    .ant-select-arrow {
      opacity: 50%;
    }
  }

  .table-product-preview {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    margin-right: 16px;
    img {
      width: 100%;
      height: auto;
    }
  }

  .status-item {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    margin: auto;
  }

  .total-products {
    color: var(--bg-color-btn);
    background-color: var(--bg-color-input);
    border-radius: 5px 0 0 0;
    min-width: fit-content;
    padding: 5px;
    padding-right: 14px;
    font-weight: 600;
    position: fixed;
    bottom: 0;
    right: 0;
  }
}

.no-image {
  background: url("./assets/images/no-image.svg") no-repeat center center;
  background-size: contain;
  width: 100%;
  height: 100%;
}

.twin-cell {
  display: flex;
  align-items: center;
}

.three-cell {
    display: flex;
    align-items: center;
    flex-direction: column;
}


// .ant-table-thead > tr > th {
//   padding: 14px;
// }

// Modal

.modal-wrap {
  display: flex;
  align-items: center;
  .modal {
    top: 0;
    padding-bottom: 0;
    .ant-modal-content {
      max-height: calc(100Vh - 100px);
      overflow: hidden;
      .ant-modal-body {
        max-height: calc(100Vh - 218px);
        overflow: auto;
      }
    }
  }
}

.ant-modal-content {
  border: 1px solid #f2f3f8;
  border-radius: 16px;
  background-clip: border-box;
  box-shadow: none;

  .ant-modal-header {
    background: transparent;
  }

  .ant-modal-title {
    font: normal normal bold 18px/24px Jost;
    color: var(--text-color-main);
  }

  .ant-modal-body {
    font-size: 16px;
    color: var(--text-color-main);
  }

  .ant-modal-footer {
    display: flex;
    justify-content: flex-end;
  }

  .delete-title {
    color: var(--text-color-main);
    font-size: 17px;
    font-weight: 500;
  }
}

// Form

.ant-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  &.custom-modal-form {
    &.login {
      input {
        height: 50px;
        background-color: #f0eef6;
      }
      .ant-input-affix-wrapper,
      .ant-input-affix-wrapper:hover,
      .ant-form-item-has-error .ant-input {
        background-color: #f0eef6;
      }
    }
    .divider {
      width: 100%;
      height: 1px;
      background: var(--text-color-main-calm);
      margin-bottom: 16px;
    }
    .ant-row {
      width: 100%;
    }

    .ant-form-item {
      width: 100%;
    }

    .ant-input-affix-wrapper {
      padding: 0 12px;
      border-radius: 12px;
      border-color: var(--border-input-color);
    }

    .ant-input-affix-wrapper-focused {
      box-shadow: none;
    }

    input {
      margin-bottom: 0;
      height: 40px;
      &:hover {
        border-color: var(--border-input-color);
      }
    }

    .ant-picker, .ant-input-number {
      width: 100%;
      border-radius: 12px;
      border: 1px solid var(--border-input-color);
      padding: 0 12px;
      input {
        padding: 0;
        border: none;
      }
      .ant-input-number-handler-wrap {
        display: none;
      }
    }

    .ant-form-item-has-error {
      .ant-picker, .ant-input-number {
        border-color: #ff4d4f;
      }
    }

    .ant-select {
      height: 40px;
      &:hover {
        .ant-select-selector {
          border-color: var(--border-input-color);
        }
      }
      &.ant-select-multiple {
        min-height: 40px;
        .ant-select-selector {
          min-height: 40px;
          height: auto;
        }
      }

      .ant-select-selector {
        height: 40px;
      }
    }
  }

  .ant-form-item-label {
    padding-bottom: 0;
    margin-left: 16px;
    label {
      font-size: 12px;
      font-weight: 600;
      opacity: 0.5;
      color: var(--text-color-main);
    }
  }

  input, .ant-input {
    width: 100%;
    margin-bottom: 16px;
    height: 48px;
    border-radius: 12px;
    border: 1px solid var(--border-input-color);
    padding: 4px 12px;
    color: var(--text-color-main);
    font: normal normal normal 14px/16px Jost;

    &:focus {
      border-color: var(--border-input-color);
      box-shadow: none;
    }
  }

  .ant-input-number-focused,
  .ant-picker-focused {
    box-shadow: none;
  }

  .ant-select {
    width: 100%;
    height: 48px;
    padding: 0 11px;
    background-color: #fff;
    border: 1px solid #F2F3F8;
    border-radius: 12px;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    display: flex;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    line-height: 1.5715;
    list-style: none;
    font-feature-settings: 'tnum', "tnum";
    position: relative;
    display: inline-block;
    cursor: pointer;
    &.ant-select-multiple {
      min-height: 48px;
      .ant-select-selector {
        min-height: 48px;
        height: auto;
      }
    }
    &.ant-select-disabled {
      .ant-select-selector {
        background: #f5f5f5;
      }
    }

    .ant-select-selector {
      display: flex;
      height: 48px;
      position: relative;
      background-color: #fff;
      border: 1px solid var(--border-input-color);
      border-radius: 12px;
      transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    }

    .ant-select-selection-search {
      display: flex;
      align-items: center;
      position: absolute;
      top: 0;
      right: 25px;
      bottom: 0;
      left: 11px;
    }

    .ant-select-selection-item {
      color: var(--text-color-main);
      font: normal normal normal 14px/16px Jost;
      display: flex;
      text-align: left;
      align-items: center;
      flex: 1 1;
      overflow: hidden;
      font-weight: normal;
      white-space: nowrap;
      text-overflow: ellipsis;
      padding: 0;
      line-height: 30px;
      transition: all 0.3s;
    }
  }
  .ant-select-focused {
    .ant-select-selector {
      box-shadow: none !important;
    }
  }
}

.ant-picker, .ant-input-number {
  width: 100%;
  border-radius: 12px;
  border: 1px solid var(--border-input-color);
  padding: 0 12px;
  input {
    padding: 0;
    border: none;
  }
  .ant-input-number-handler-wrap {
    display: none;
  }
}

.ant-input-number-focused,
.ant-picker-focused,
.ant-input-affix-wrapper-focused {
  box-shadow: none;
}
.text-center {
  input {
    text-align: center;
  }
}

.ant-input[disabled],
.ant-picker-input > input[disabled] {
  color: var(--text-color-main);
}

.search-input {
  width: 170px;
  margin-right: 12px;
  .ant-input-affix-wrapper {
    height: 40px;
    border-radius: 5px !important;
    border: 1px solid var(--border-input-color);
    &:hover {
      border: 1px solid var(--border-input-color);
    }
  }
  .ant-input-group-addon {
    display: none;
  }
  ::placeholder {
    font-size: 15px;
    font-family: Jost;
    color: #bfbfbf;;
  }
}

// Button

.ant-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  font: normal normal 600 16px/24px Jost;
  color: var(--bg-color-main);
  border-radius: 5px;
  height: 40px !important;
  min-width: 80px;
  border: 1px solid transparent;
  padding: 8px 12px;
  &:hover, &:active {
    color: var(--bg-color-main);
  }
  &.icon {
    > span {
      margin-left: 8px;
    }
  }
}

.ant-btn.btn-primary,
.ant-btn.btn-primary:focus {
  background: var(--bg-color-btn);
  color: var(--bg-color-main);
  border-color: var(--bg-color-btn);
}

.ant-btn.btn-primary:hover {
  background: var(--bg-color-btn-hover);
  border-color: var(--bg-color-btn-hover);
}

.ant-btn.btn-primary:active {
  background: #0d1d58;
}

.ant-btn.btn-primary.white,
.ant-btn.btn-primary.white:focus {
  background: transparent;
  // background: var(--bg-color-main);
  color: var(--text-color-main);
  border: 1px solid var(--text-color-main);
  // border: 1px solid var(--bg-color-btn);
  &.withoutborder {
    border: none;
    background: var(--border-input-color);
    color: var(--text-color-main);
  }
}

.ant-btn.btn-primary.white:hover {
  background: var(--bg-color-btn-hover);
  color: var(--bg-color-main);
  border: 1px solid var(--bg-color-btn-hover);
  &.withoutborder {
    border: none;
    background: var(--border-input-color);
    color: var(--text-color-main);
  }
}

.ant-btn.btn-primary.white:active {
  background: #0d1d58;
  color: var(--bg-color-main);
}

.ant-btn.btn-primary.greyout {
  background: #f5f5f5;
  color: #9fa1a8;
  border: 1px solid var(--bg-color-btn);

  &:hover {
    background: #f5f5f5;
    color: #9fa1a8;
  }
}

.ant-btn.btn-primary.orange,
.ant-btn.btn-primary.orange:focus {
  background: #FF6B0F;
  color: var(--bg-color-main);
  border-color: #FF6B0F;
}

.ant-btn.btn-primary.orange:hover {
  background: #db4617;
}

.ant-btn.btn-primary.orange:active {
  background: #c73420;
}

.ant-btn.btn-primary.red,
.ant-btn.btn-primary.red:focus {
  background: #f63659;
  color: var(--bg-color-main);
  border-color: #f63659;
}

.ant-btn.btn-primary.red:hover {
  background: #d12951;
}

.ant-btn.btn-primary.red:active {
  background: #a81b45;
}

.ant-btn-link,
.ant-btn-link:focus {
  color: #3680F6;
  font: normal normal 600 17px/32px Jost;
}

.ant-btn-link:hover {
  color: #112b89;
}

.ant-btn-link:active {
  color: #0d1d58;
}

.ant-btn.btn-primary[disabled] {
  background: #f5f5f5;
  color: var(--bg-color-btn);
  border: 1px solid var(--bg-color-btn);

  &:hover {
    background: #f5f5f5;
    color: var(--bg-color-btn);
  }
}

// Popconfirm

.download-popover .ant-popover-content {
  min-width: 200px;
  width: auto;
}

.ant-popover-content {
  width: 300px;

  .ant-popover-message {
    font: normal normal 500 16px/24px Jost;
  }

  .ant-popover-buttons {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
}

// Spin

.ant-spin-nested-loading > div > .ant-spin {
  // max-height: none;
}

// Table

.ant-table-wrapper {
  &.card {
    // height: calc(100% - 80px);
    margin-bottom: 40px;
    overflow: auto;
    border-radius: 5px;
    td {
      white-space: initial;
      &.too-long {
        word-break: break-all;
      }
    }
  }
  .ant-table-thead > tr > th {
    color: var(--text-color-main-calm);
  }
}

.table-action {
  display: flex;
  align-items: center;
  justify-content: space-around;
  &.column {
    flex-direction: column;
  }
}

// Tooltip

.ant-tooltip {
  img {
    max-width: 200px;
    max-height: 200px;
  }
}

.ant-typography-copy {
  // display: block!important;
  // margin-top: 3px;
  color:#0d1d58;
}

td .ant-typography {
  // white-space: nowrap;
  // text-overflow: ellipsis;
  // overflow: hidden;
  color:#1890ff;
}