.modal-wrap {
  .ant-form {
    .top {
      display: flex;
      width: 100%;
      .img-block {
        display: flex;
        flex-direction: column;
        flex-direction: column;
        justify-content: space-evenly;
        margin-right: 40px;
      }
      .ava {
        width: 160px;
        height: 160px;
        border: 1px solid #f0f0f0;
        border-radius: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 24px;
        color: var(--text-color-main);
        padding: 16px;
        img {
          width: 100%;
          height: auto;
        }
      }
      .top-form {
        flex: 1;
      }
    }
    .order-info-block {
      width: 100%;
      .title {
        font-size: 24px;
        text-decoration: underline;
        color: var(--text-color-main);
        margin: 16px 0;
      }
      .delete-cell {
        display: flex;
        justify-content: flex-end;
        // align-items: center;
        > * {
          cursor: pointer;
        }
      }
    }
  }
}

textarea.ant-input.add-comment-input {
  padding: 6px;
  border-radius: 8px;
  border-color: var(--border-input-color);
}

.add-comment-button-cancel {
  margin-left: auto;
  margin-right: 15px;
  margin-top: 10px;
  &:hover {
    cursor: pointer;
    color: var(--text-red-color);
  }
}

.add-comment-button-add {
  margin-top: 10px;
  color: var(--bg-color-btn);
  &:hover {
    cursor: pointer;
    color: var(--bg-color-btn-active);
  }
}

.comment {
  margin-bottom: 4px;
  width: fit-content;
  color: var(--text-color-main-calm);

  &-date {
    font-size: 12px;
    margin-right: 10px;
  }
 
  &-name {
    font-size: 12px;
  }

  &-text {
    color: var(--text-color-main);
    background: var(--bg-color-disabled);
    border-radius: 8px;
    padding: 2px 8px;
  }
}
