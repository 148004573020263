.container {
  min-height: 100vh;
  position: relative;
}

.main {
  min-height: calc(100Vh - 72px);
  background: var(--bg-color-input);
  // overflow-y: scroll;
}
