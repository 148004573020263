.popover-wrap {
  width: auto;
  height: 40px;
  font-size: 15px;
  color: #bfbfbf;
  line-height: 19px;
  margin-left: 12px;
  border-radius: 5px;
  border: 1px solid var(--border-input-color);
  padding: 10px 8px;
  display: flex;
  align-items: center;
  cursor: pointer;
  background: var(--bg-color-main);
  text-align: center;
}

.dashboard-content {
  background: var(--bg-color-input);
  padding: 24px;
  .title {
    font-size: 20px;
    line-height: 32px;
    font-weight: 600;
    color: var(--text-color-main);
    margin-left: 40px;
  }
  .chart-block {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: var(--bg-color-main);
    border-radius: 16px;
    padding: 24px;
    margin-bottom: 24px;
    .chart {
      width: 30%;
      height: 200px;
    }
    .data {
      display: flex;
      flex-direction: column;
      align-items: center;
      flex: 1;
      &.bottom {
        .name {
          > span {
            font-size: 1.82Vw;
            font-weight: 600;
            color: var(--text-color-main);
            &:first-child{
              min-width: 200px;
              display: inline-block;
              text-align: right;
            }
          }
        }
      }
      .value {
        font-size: 2Vw;
        font-weight: 600;
        color: var(--text-color-main);
        display: flex;
        flex-direction: column;
        text-align: right;
        .name {
          width: 100%;
          font-size: 16px;
          font-weight: 600;
          color: var(--text-color-main-calm);
        }
      }
    }
  }
}
