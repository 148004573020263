.table {

  &-wrapper {
    height: calc(100% - 58px);
  }

  td, th {
    vertical-align: middle;
  }

  td {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .ant-table-column-title {
    color: var(--text-color-main-calm);
    font-size: 14px;
    line-height: 14px;
  }

  .ant-checkbox-indeterminate .ant-checkbox-inner::after {
    left: 50%;
  }

  .ant-checkbox-inner {
    width: 24px;
    height: 24px;

    &:after {
      left: 30%;
    }
  }

  .ant-table {
    color: var(--text-color-main);
    font-family: Jost, sans-serif;
  }

  .ant-table-cell {
    font-weight: 500 !important;
    font-size: 14px !important;
    line-height: 18px !important;
    padding: 16px 8px !important;

    &--underline {
      text-decoration: underline;
    }

    &--link {
      cursor: pointer;
    }
  }

  .ant-table-tbody > tr.ant-table-row-selected:hover > td {
    background: var(--bg-color-input);
  }

}
